<template>
  <div class="drp-view-edit-integer">
    <div
      class="value-cursor"
      :class="pSpecialColors"
      v-if="mode === VIEW_MODE"
      @click="mode = EDIT_MODE"
    >
      {{ value }}
    </div>
    <div v-else-if="mode === EDIT_MODE" class="d-flex flex-row align-center w-100">
      <v-text-field
        class="value-text-field"
        :label="pLabel"
        v-model="value"
        type="number"
        min="0"
        @input="+value < 0 ? value = 0 : false"
      ></v-text-field>
      <div class="d-flex">
        <v-icon
          class="ml-2"
          color="green"
          @click="save()"
          :disabled="+value === pValue || value === ''"
        >mdi-check</v-icon>
        <v-icon
          class="ml-2"
          color="red"
          @click="cancel()"
        >mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
const VIEW_MODE = "VIEW";
const EDIT_MODE = "EDIT";

export default {
  name: "ViewEditInteger",

  props: {
    pValue: Number,
    pLabel: String,
    pSpecialColors: Array,
  },

  data: () => ({
    VIEW_MODE,
    EDIT_MODE,

    value: 0,

    mode: VIEW_MODE,
  }),

  methods: {
    save() {
      this.mode = VIEW_MODE;
      this.$emit("onChangeValue", +this.value);
    },

    cancel() {
      this.mode = VIEW_MODE;
      this.value = this.pValue;
    },
  },

  mounted() {
    this.value = this.pValue;
  },
};
</script>

<style scoped>
.value-text-field {
  width: 30px !important;
}
.value-cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
.drp-view-edit-integer {
  .v-text-field__details {
    display: none !important;
  }
}
</style>
